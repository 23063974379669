const pageHeader = () => {
  ScrollReveal().reveal(".page-header"); // eslint-disable-line
  ScrollReveal().reveal(".page-header #breadcrumbs", { delay: 500 }); // eslint-disable-line
  ScrollReveal().reveal(".page-header .entry-title", { delay: 800 }); // eslint-disable-line
  ScrollReveal().reveal(".page-header .content p", { delay: 1100 }); // eslint-disable-line
  ScrollReveal().reveal("article.hair-clinic .entry-content > p:first-child, article.page .entry-content > p:first-child", { delay: 1000 }); // eslint-disable-line
};

export default function scrollReveal() {
  // Page header
  pageHeader();

  // Blocks
  // Blocks animations are in each block js file
}
