export default function changeClass(anchorToggle, anchorContentWrapper) {
  anchorToggle.classList.toggle("open");
  anchorContentWrapper.classList.toggle("open");

  const anchorPos = anchorToggle.getBoundingClientRect();

  if (anchorContentWrapper.classList.contains("open")) {
    anchorContentWrapper.style.setProperty("top", `${anchorPos.y - 70}px`);
  } else {
    anchorContentWrapper.style.removeProperty("top");
  }
}
