export default function getTheTitle(title) {
  const subtitle = title.querySelector("p.subtitle");
  const h2 = title.querySelector("h2");

  const subtitleText = subtitle?.innerText || "";

  const h2Text = h2?.innerText || "";

  // if subtitle and h2 is empty
  if (subtitleText.length < 1 && h2Text.length < 1) {
    return "";
  }

  // if subtitleText is empty
  if (subtitleText.length < 1) {
    return h2Text;
  }

  // if h2Text is empty
  if (h2Text.length < 1) {
    return subtitleText;
  }

  return `${subtitleText} ${h2Text}`;
}
