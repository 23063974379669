export default function phoneClick() {
  if (!window.matchMedia("screen and (min-width: 62em)")) {
    return;
  }

  function changeText() {
    const link = document.getElementById("phoneBtn").getAttribute("href");
    let phone = link.replace("tel:", "");
    phone = phone.replace("+1", "");

    if (document.getElementById("phoneBtn").innerHTML !== phone) {
      document.getElementById("phoneBtn").innerHTML = phone;
    }
  }

  document.getElementById("phoneBtn").addEventListener("click", changeText);
}
