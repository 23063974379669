import addItemToHtml from "./addItemToHtml";
import getTheTitle from "./getTheTitle";

function pushDataToArray(element, dataArray) {
  dataArray.push({
    id: element.id,
    title: element.title,
  });

  return dataArray;
}

export default function generateHTML(blocks) {
  let dataToOutput = [];

  [...blocks].forEach((item) => {
    // add h2 heading item to dataOutput
    if (item.className === "wp-block-heading") {
      dataToOutput = pushDataToArray(
        {
          id: item.id,
          title: item.innerHTML,
        },
        dataToOutput
      );
    }

    // to get single list items
    const singleBlock = item.querySelectorAll(".single-list");

    if (singleBlock.length < 1) {
      return;
    }

    [...singleBlock].forEach((element) => {
      const id = element.getAttribute("id");
      let title = element.querySelector(".title-content");

      title = getTheTitle(title);

      dataToOutput = pushDataToArray(
        {
          id,
          title,
        },
        dataToOutput
      );
    });
  });

  dataToOutput.forEach((d) => {
    addItemToHtml(d);
  });
}
