import mixitup from "mixitup";

export default function mixitupPosts() {
  const element = document.querySelector(".posts-wrapper");

  if (!element || element.length === 0) {
    return;
  }

  const mixer = mixitup(".posts-wrapper", {// eslint-disable-line
    selectors: {
      target: ".post",
    },
    animation: {
      duration: 300,
    },
  });

  // mixer();
}
