import "../scss/theme.scss";

import hamburgerMenu from "./components/hamburger-menu";
import menuDropdown from "./components/menuDropdown";
import mixitupPosts from "./components/mixitupPosts";
import anchorsMenu from "./components/anchorsMenu";
import phoneClick from "./components/phoneClick";
import mobileShopSidebar from "./components/mobileShopSidebar";
import scrollReveal from "./components/scrollReveal";

// wait until the window is fully loaded to run js
window.addEventListener("load", () => {
  hamburgerMenu();
  menuDropdown();
  mixitupPosts();
  phoneClick();
  anchorsMenu();
  mobileShopSidebar();
  scrollReveal();
});
