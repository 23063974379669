import changeClass from "./changeClass";
import removeClass from "./removeClass";

export default function activateMenuToggle(anchorToggle) {
  const anchorContentWrapper = document.getElementById(
    "anchor-content-wrapper"
  );

  anchorToggle.addEventListener("click", () => {
    changeClass(anchorToggle, anchorContentWrapper);
    document.body.classList.add("anchor-menu-open");
  });

  const anchorClose = document.getElementById("anchor-menu-close");
  anchorClose.addEventListener("click", (e) => {
    removeClass(e, anchorToggle, anchorContentWrapper);
    document.body.classList.remove("anchor-menu-open");
  });

  const links = anchorContentWrapper.querySelectorAll("li a");

  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      document.body.classList.remove("anchor-menu-open");
      removeClass(e, anchorToggle, anchorContentWrapper);
    });
  });
}
