export default function addItemToHtml(item) {
  const contentDiv = document.getElementById("anchor-content");

  const li = document.createElement("li");
  const a = document.createElement("a");

  a.innerHTML = item.title;
  a.href = `#${item.id}`;

  li.appendChild(a);
  contentDiv.appendChild(li);
}
