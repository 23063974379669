import activateMenuToggle from "./parts/anchorparts/activateMenuToggle";
import generateHTML from "./parts/anchorparts/generateHtml";

export default function anchorsMenu() {
  const blocks = document.querySelectorAll(
    "h2.wp-block-heading, .block-default-content"
  );
  const anchorToggle = document.getElementById("anchor-menu");

  // if no blocks are in the page or no side Menu Toggle
  if (blocks.length === 0 || !anchorToggle) {
    return;
  }

  // If there is block show the anchor menu
  anchorToggle.style.display = "block";

  document.body.classList.add("has-anchor");

  generateHTML(blocks);

  activateMenuToggle(anchorToggle);
}
